import * as React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { Link, useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  const FourOhFour = (
    useStaticQuery(graphql`
      {
        wpPage(title: { eq: "404" }) {
            content
            title
        }
      }
    `).wpPage
  );
  return (
    browser && (
      <Layout title={FourOhFour.title} wordPressContent={FourOhFour.content}>
        <SEO title="404" />
      </Layout>
    )
  );
};

export default NotFoundPage;